import {setCookie, getCookie, deleteCookie, get_utn_args, is_utm_cookies, is_utm_request, set_utm_cookies} from "./utm";

function addError(el) {
    el.before('<span class="error">נא למלא שדה זה</span>');
    el.parent().addClass('wrapper-error');
}

(function ($) {

    $.fn.equalizeHeight = function (options) {

        // פונקציה מתקדמת ליישור גבהים של אלמנטים שונים לפי סלקטור

        var settings = $.extend({
            'maxWindowWidth': false,
            'equaltop': false
        }, options);
        this.css({'height': 'inherit'});
        if (!settings.maxWindowWidth || settings.maxWindowWidth < $(window).width()) {
            var maxHeight = 0;
            var currentTop = false;
            var tempArray = [];
            if (!settings.equaltop) {
                this.each(function (index, el) {
                    maxHeight = Math.max(maxHeight, $(el).css('box-sizing') == 'border-box' ? $(el).innerHeight() : $(el).height());
                });
                this.css({'height': maxHeight + 'px'});
            } else {
                this.each(function (index, el) {
                    if (currentTop === false || Math.abs(currentTop - $(el).position().top) > 5) {
                        $(tempArray).css({'height': maxHeight + 'px'});
                        maxHeight = 0;
                        currentTop = Math.floor($(el).position().top);
                        tempArray = [];
                    }
                    maxHeight = Math.max(maxHeight, $(el).css('box-sizing') == 'border-box' ? $(el).innerHeight() : $(el).height());
                    tempArray.push(el);
                });
                $(tempArray).css({'height': maxHeight + 'px'});

            }
        }
    };
})(jQuery)

function fixSize() {
    if ($(window).width() < 641) {
        $('.hero-slider .slide').equalizeHeight({
            'equaltop': false
        });
    }


}

$(window).on('load', function () {
    fixSize()
})
$(document).ready(function () {
    fixSize()
    var form = $('#contact-form form');
    var utm_args = JSON.parse(get_utn_args());
    console.log(utm_args);
    for (var utm_key in utm_args) {
        form.find('input[name=' + utm_key + ']').remove();
        form.append('<input type="hidden" value="' + utm_args[utm_key] + '" data="' + utm_args[utm_key] + '" name="' + utm_key + '">');
    }
    form.find('input[name=referrer]').remove();
    if (window.location.href) {
        form.append('<input type="hidden" value="' + window.location.href + '" data="' + window.location.href + '" name="referrer">');
    }

    $('select').selectBox({
        mobile: true,
    });
    $('.hero-slider').slick({
        rtl: true,
        arrows: false,
        dots: true,

    });
    if ($(window).width() < 999) {
        let currentSlide1 = $('.hero-slider').slick('slickCurrentSlide');
        $('.hero-slider').on('afterChange', function (event, slick, currentSlide, nextSlide) {
            // $('.slide').eq(currentSlide).append(`<span class="count-slide"> </span>`)
            $('.count-slide').text(currentSlide + 1)

        });
        $('.slide').append(`<span class="count-slide">${currentSlide1 + 1}</span>`)

    }

    function validateForm() {
        let email = $('form .email')
        let check = $('form #check')
        let select = $('.select')
        let flag = true
        $(".error").remove();
        $('.wrapper-item').removeClass('wrapper-error')
        $('.required').each(function (i, el) {
            if ($(el).val().length < 1) {
                flag = false
                addError($(el))
            }
        })
        if (select.val() === 'סוג לקוח') {
            flag = false
            addError(select)
        }

        if (email.val().length < 1) {
            flag = false
            addError(email)
        } else {
            var regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var validEmail = regEx.test(email.val());
            if (!validEmail) {
                flag = false
                $('.email').before('<span class="error"> נא להזין כתובת דוא"ל תקינה</span>');
            }
        }
        return flag
    }


    $(document).on('submit', '#contact-form form', function (e) {
        e.preventDefault();

        let success = validateForm()
        if (success) {
            $('input[type=submit]').attr('disabled', 'disabled')
            $('.loader').css({'opacity': 1})
            $.ajax({
                type: "POST",
                url: 'send-form.php',
                data: $('#contact-form form').serialize(), // serializes the form's elements.
                success: function (data) {
                    console.log(data);
                    $('.thankyou-section').fadeIn()
                    window.location.href = 'thankyou.html';
                }
            });
        }

    })

    $('.to-form').click(function (e) {
        e.preventDefault()
        $('html,body').animate({
            scrollTop: $('#contact-form').offset().top
        }, 500);

    })
    AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true
    });

    // let scrolltop
    let scrolltop = $(document).scrollTop()
    scrollAnimate(scrolltop)
    $(document).scroll(function () {
        scrolltop = $(document).scrollTop()
        scrollAnimate(scrolltop)
        if ($(window).width() > 992) {


            if ($('.slider-section').offset().top + $('.slider-section').height() - 100 < $(document).scrollTop()) {
                $('.logo').addClass('dark')
            }else{
                $('.logo').removeClass('dark')
            }
        }
    })

    function scrollAnimate(scrolltop) {
        $('.animation-block').each(function (i, el) {
            if (scrolltop + $(window).height() > $(el).offset().top) {
                $(el).addClass('active')
            }
        })
    }

    $('[data-fancybox]').fancybox({
        loop: true,
        zoom: false,
        clickContent: 'close',
        btnTpl: {

            arrowLeft:
                '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="חזרה"> <span class="text-btn">קטגוריה הבאה </span>' +
                "</button>",

            arrowRight:
                '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="קדימה"> <span class="text-btn">קטגוריה קודמת</span>' +
                "</button>",
        }
    });


})


$(window).resize(function () {

    fixSize()

})