
 export function is_utm_request(){
    var request = true;
    var utm_required_args = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_nooverride',
        'utm_referrer'
    ];
    var url = new URL(window.location.href);
    utm_required_args.forEach(function(utm_key) {

        if(!(typeof(url.searchParams.get(utm_key)) == "string")){
            request = false;
        }

    });
    return request;
}

 export function get_utn_args(){
    var utm_args = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_nooverride',
        'utm_referrer'
    ];
    var url = new URL(window.location.href);
    var utm_assoc_args = {};
    utm_args.forEach(function(utm_key) {

        if(!(typeof(url.searchParams.get(utm_key)) == "undefined")){
            if(url.searchParams.get(utm_key) || typeof(url.searchParams.get(utm_key)) == "string")
                utm_assoc_args[utm_key] = url.searchParams.get(utm_key);
        }

    });
    return JSON.stringify(utm_assoc_args);
}

 export function setCookie(name, value, options = {}) {

    options = {
        path: '/',
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

 export function deleteCookie(name) {
    setCookie(name, "", {
        'max-age': -1
    })
}

 export function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

 export function is_utm_cookies(){
     if(getCookie('utm') === 'true'){
        return true;
    }
    return false;
}

 export function set_utm_cookies(){
    setCookie('utm', 'true');
    setCookie('utm_args', get_utn_args());
    setCookie('referrer', window.location.href);
}

if(is_utm_request() && !is_utm_cookies()){
    set_utm_cookies();
}
